import { Route, Switch } from "react-router-dom";
import { AuthPage, HomePage, SearchPage, UserPage, NotFoundPage } from "./pages";
import { Layout } from './components'
import { useCallback, useEffect } from "react";

function App() {

  return (
    <Layout>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/search" component={SearchPage} />
        <Route path="/auth" component={AuthPage} />
        <Route path="/user" component={UserPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Layout>
  );
}

export default App;
