import { useEffect } from "react";
import { useLocation } from "react-router-dom"
import { NavLink } from "react-router-dom";
import { HomeIcon } from "../assets/icons/home-icon";
import { UserIcon } from "../assets/icons/user-icon";

export const Navbar = () => {
  const location = useLocation();

  useEffect(() => {
    console.log(location);
  }, [ location ]);

  return (
    <div className="flex border-t  text-gray-600 py-1 bg-white">

      <NavLink className="flex-1 flex flex-col items-center justify-center text-center text-gray-600" to="/">
        <HomeIcon />
      </NavLink>

      <NavLink className="flex-1 text-center" to="/search">
        <span className="text-xs">Search</span>
      </NavLink>

      <NavLink className="flex-1 text-center" to="/">
        <div className="flex flex-col items-center">
          <UserIcon active={true}/>
        </div>
      </NavLink>
    </div>
  );
}